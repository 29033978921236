import Vue from "vue";
import VueRouter from "vue-router";
import {checkPermissions} from "../helpers";
import {userService} from "../apis";

Vue.use(VueRouter);

const publicPagetypes = {
  publicWithoutAuth: 1,
  public: 2,
};

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {publicPageType: publicPagetypes.publicWithoutAuth},
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {publicPageType: publicPagetypes.publicWithoutAuth},
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/ForgotPassword.vue"),
    meta: {publicPageType: publicPagetypes.publicWithoutAuth},
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
    meta: {publicPageType: publicPagetypes.publicWithoutAuth},
  },
  {
    path: "/create-password",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
    meta: {publicPageType: publicPagetypes.publicWithoutAuth},
  }, 
  {
    path: "/location-partners/:id",
    name: "LocationPartners",
    // component: Dashboard,
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import("../views/Location/LocationDetail/LocationPartners.vue"),
  },
  {
    path: "/partner-detail/:id",
    name: "PartnerDetail",
    component: () => import("../views/PartnerDetail/index.vue"),
  },
  {
    path: "/location-partner-create/:location",
    name: "PartnerCreate",
    component: () =>
        import(
            "../views/Location/LocationDetail/LocationPartnerAddEditForm/index.vue"
            ),
  },
  {
    path: "/location-partner-edit/:id",
    name: "Locations",
    component: () =>
        import(
            "../views/Location/LocationDetail/LocationPartnerAddEditForm/index.vue"
            ),
  },
  {
    path: "/locations",
    name: "Locations",
    component: () => import("../views/Location/index.vue"),
  },
  {
    path: "/overview/:id",
    name: "Overview",
    component: () => import("../views/Location/LocationDetail/Overview.vue"),
  },
  {
    path: "/location-cities/:id",
    name: "LocationCities",
    component: () =>
        import("../views/Location/LocationDetail/LocationCities.vue"),
  },
  {
    path: "/location-employees/:id/",
    name: "Employees",
    component: () =>
        import("../views/Location/LocationDetail/LocationEmployees.vue"),
  },
  {
    path: "/:id/location-admin",
    name: "LocationAdmin",
    component: () =>
        import("../views/Location/LocationDetail/LocationAdmin.vue"),
  },
  {
    path: "/cities",
    name: "Cities",
    component: () => import("../views/City.vue"),
  },
  {
    path: "/city-admin",
    name: "CityAdmin",
    component: () => import("../views/CityAdmin.vue"),
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/Users.vue"),
  },
  {
    path: "/archive-users",
    name: "LeadDetail",
    component: () => import("../views/ArchiveUsers.vue"),
  },
  {
    path: "/user-detail/:id",
    name: "UserDetail",
    component: () => import("../views/UserDetail.vue"),
  },
  {
    path: "/users-groups",
    name: "UsersGroups",
    component: () => import("../views/UsersGroups.vue"),
  },
  {
    path: "/cities-groups",
    name: "CityGroups",
    component: () => import("../views/CityGroups.vue"),
  },
  {
    path: "/categories",
    name: "Categories",
    component: () => import("../views/Categories.vue"),
  },
  {
    path: "/category-detail",
    name: "CategoryDetail",
    component: () => import("../views/CategoryDetail.vue"),
  },
  {
    path: "/measures",
    name: "Measures",
    component: () => import("../views/Measures.vue"),
  },
  {
    path: "/measure-detail",
    name: "MeasureDetail",
    component: () => import("../views/MeasureDetail.vue"),
  },
  {
    path: "/reviews/",
    name: "Reviews",
    component: () => import("../views/Review/Review.vue"),
  },
  {
    path: "/contact",
    name: "Contacts",
    component: () => import("../views/Contact/Contact.vue"),
  },
  {
    path: "/contact-form",
    name: "ContactForm",
    component: () => import("../views/Contact/ContactForm/Stepper.vue"),
  },
  {
    path: "/contact-detail",
    name: "ContactDetail",
    component: () => import("../views/Contact/ContactDetail/index.vue"),
  },
  {
    path: "/create-contact",
    name: "CreateContact",
    component: () => import("../views/Contact/ContactAddEditForm/Stepper.vue"),
  },
  {
    path: "/edit-contact/:id?",
    name: "EditContact",
    component: () => import("../views/Contact/ContactAddEditForm/Stepper.vue"),
  },
  {
    path: "/leads",
    name: "Leads",
    component: () => import("../views/Lead/Lead.vue"),
  },
  {
    path: "/lead-detail/:id",
    name: "LeadDetail",
    component: () => import("../views/Lead/LeadDetail/LeadDetailNew.vue"),
  },
  {
    path: "/lead-partner",
    name: "LeadPartner",
    component: () => import("../views/Lead/AssignPartner.vue"),
  },
  {
    path: "/archive-leads",
    name: "LeadDetail",
    component: () => import("../views/Lead/archiveLead.vue"),
  },
  {
    path: "/pro-forma-quote/:id",
    name: "ProForma Quote",
    component: () =>
        import("../views/Lead/LeadDetail/ProFormaQuoteStepper/index.vue"),
  },
  {
    path: "/quote-request",
    name: "Quote Request",
    component: () => import("../views/Quote/QuoteRequest.vue"),
  },
  {
    path: "/send-quote/:id",
    name: "SendQuote",
    component: () => import("../views/Quote/QuotationDetail.vue"),
  },
  {
    path: "/upload-quote",
    name: "UploadQuote",
    component: () => import("../views/Quote/UploadQuote.vue"),
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: () => import("../views/Tasks/index.vue"),
  },
  {
    path: "/task-details/:id",
    name: "TaskDetails",
    component: () => import("../views/Tasks/TaskDetail.vue"),
  },
  {
    path: "/city-overview",
    name: "City Overview",
    component: () => import("../views/CityDashboard/CityOverview.vue"),
  },
  {
    path: "/city-dashboard",
    name: "City Dashboard",
    component: () => import("../views/CityDashboard/index.vue"),
  },
  {
    path: "/advisures-form/:hash",
    name: "Advisures Form",
    component: () => import("../views/AdvisuresForm/index.vue"),
  },
  {
    path: "/house-passport/:hash",
    name: "House Passport",
    component: () => import("../views/HousePassport/index.vue"),
  },
  {
    path: "/configuration",
    name: "Configuration",
    component: () => import("../views/Configurations/index.vue"),
  },
  {
    path: "/partner-dashboard",
    name: "Partner Dashboard",
    component: () => import("../views/PartnerDashboard/index.vue"),
  },
  {
    path: "/orders",
    name: "Orders",
    component: () => import("../views/Orders/index.vue"),
  },
  {
    path: "/order-detail/:orderId",
    name: "Order Detail",
    component: () =>
        import("../views/HousePassport/Orders/OrderDetail/OrderDetailNew.vue"),
  },
  {
    path: "/quotation-webpage",
    name: "Quotation Webpage",
    component: () => import("../views/Quote/QuotationWebpage/index.vue"),
    meta: {publicPageType: publicPagetypes.publicWithoutAuth},
  },
  {
    path: "/create-energy-reports",
    name: "CreateEnergyReport",
    component: () => import("../views/EnergyReport/EnergyReportAddEditForm/Stepper.vue"),
  },
  {
    path: "/generate-pdf-report/:energyId",
    name: "CreateEnergyReport",
    component: () => import("../views/EnergyReport/EnergyReportAddEditForm/generatePDFReport.vue"),
  },
  {
    path: "/energy-reports",
    name: "Energy Reports",
    component: () => import("../views/EnergyReport/EnergyReport.vue"),
  },
  {
    path: "/energy-report-detail/:energyId",
    name: "EnergyReportDetail",
    component: () => import("../views/EnergyReport/EnergyDetail/EnergyDetailNew.vue"),
  },
  {
    path: "/energy-report-form/:energyId",
    name: "EnergyReportForm",
    component: () => import("../views/EnergyReport/EnergyReportAdvisorForm/index.vue"),
  },
  // {
  //   path: "/advisor-scan-form",
  //   name: "Advisor Scan Form",
  //   component: () => import("../views/EnergyReport/AdvisorWebpage/index.vue"),
  //   meta: {publicPageType: publicPagetypes.publicWithoutAuth},
  // },
  // {
  //   path: "/review",
  //   name: "Review",
  //   component: () => import("../views/Review.vue"),
  //   meta: {requiresQueryParams: ["partner", "token"], publicPageType: publicPagetypes.publicWithoutAuth},
  // },
  {
    path: "/thankyou",
    name: "Thankyou",
    component: () => import("@/views/Thankyou.vue"),
    meta: {publicPageType: publicPagetypes.publicWithoutAuth},
  },
  
  {
    path: "/",
    name: "Site Maintenance",
    component: () => import("../views/Maintenance.vue"),
    meta: {publicPageType: publicPagetypes.publicWithoutAuth},
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({behavior: "smooth"});
  },
});

// redirect to maintenance page if site under maintenance
let maintenance = false

router.beforeResolve((to, from, next) => {
  userService.cancelTokens.forEach((cancelToken) => {
    cancelToken.cancel("Operation cancelled");
  });
  if (localStorage.getItem("role")) {
    localStorage.clear();
    window.location.reload();
  }

  const group = localStorage.getItem("group");


  function checkMaintenence() {
    if (maintenance) {
      maintenance = false
      next({
        name: "Site Maintenance",
      });
    }
  }

  function checkRedirect() {
    if (group && group.toLowerCase() === "city admin" || group.toLowerCase() === "action admin") {
      next({
        path: "/city-overview",
      });
    } else if (group && group.toLowerCase() === "partners") {
      next({
        path: "/partner-dashboard",
      });
    } else {
      next({
        path: "/locations",
      });
    }
  }
  const store = router.app.$store;
  if (maintenance && to.name !== 'Site Maintenance') {
    checkMaintenence()
  }
  else if (to.name === 'Site Maintenance'){
    next()
  }
  else if (
      to.matched.some(
          (record) =>
              !Object.prototype.hasOwnProperty.call(record.meta, "publicPageType")
      )
  ) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.state.account.status.loggedIn) {
      if (!checkPermissions.checkTabs(to.name)) {
        checkRedirect();
      } else {
        next();
      }
    }
    else {
      next({
        path: "/login",
      });
    }
  } else if (
      to.matched.some(
          (record) =>
              record.meta.publicPageType === publicPagetypes.publicWithoutAuth
      ) &&
      store.state.account.status.loggedIn
  ) {
    checkRedirect();
  } else if (
      to.matched.some(
          (record) =>
              record.meta.publicPageType === publicPagetypes.publicWithoutAuth
      ) &&
      !store.state.account.status.loggedIn
  ) {
    next();
  } else if (!checkPermissions.checkTabs(to.name)) {
    checkRedirect();
  }

});

export default router;
